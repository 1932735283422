import image from '../../assets/img/General/logo.svg';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to='/' className={`header__logo logo`}>
      <img src={image} alt='logo' width='100%' height='100%' />
    </Link>
  );
};
export default Logo;
