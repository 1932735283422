// @ts-nocheck
import globeIconPath from "../../assets/img/Icons/globe.svg";
import selectArrowPath from "../../assets/img/Icons/select-arrow.svg";
import searchIconPath from "../../assets/img/Icons/search.svg";
import { useEffect, useRef, useState } from "react";
import countries from "../../../countries.js";
import Flag from "react-flagkit";
import * as utils from "intl-tel-input/build/js/utils";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const TelInput = ({ placeholder = "", value, error, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectSearch, setSelectSearch] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [instance, setInstance] = useState();

  const filteredCodes = countries.filter((item) => {
    if (selectSearch) {
      const str = item.name.toLowerCase();
      return str.includes(selectSearch.toLowerCase());
    } else {
      return true;
    }
  });

  const selectCountry = (code: string) => {
    instance.setCountry(code);
    setCountryCode(code);
    setIsOpen(false);

    if (!value.trim().length) {
      const country = instance.getSelectedCountryData();
      instance.setNumber("+" + country.dialCode);
    }

    onChange(instance.getNumber(intlTelInputUtils.numberFormat.E164));
  };

  const handleChange = (e) => {
    const number = instance.getNumber(intlTelInputUtils.numberFormat.E164);
    if (!number.trim().length) {
      instance.setNumber("+");
      onChange("+");
    } else {
      onChange(number);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectSearch("");
    }
  }, [isOpen]);

  useEffect(() => {
    const input = document.querySelector("#phone");
    const inst = intlTelInput(input, {
      utilsScript: utils,
      containerClass: "tel-input__container",
      customPlaceholder: () => placeholder,
    });

    inst.setNumber("+");

    setInstance(inst);

    window.intlTelInputGlobals.loadUtils("build/js/utils.js");

    input.addEventListener("countrychange", function () {
      setCountryCode(inst.getSelectedCountryData().iso2);
    });
  }, []);

  const telInputRef = useRef();

  const handleClickOutside = (event) => {
    if (
      telInputRef.current &&
      !telInputRef.current.contains(event.target) &&
      !event.target.classList.contains("tel-input__country")
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`tel-input ${isOpen ? "open" : ""}`}>
      <div
        className={`form-popup__input tel-input__country ${
          error ? "error" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="tel-input__country-icon">
          {countryCode ? (
            <Flag size={20} country={countryCode.toUpperCase()} />
          ) : (
            <img src={globeIconPath} alt="" />
          )}
        </span>
        <img className="tel-input__select-arrow" src={selectArrowPath} alt="" />
      </div>
      <input
        id="phone"
        placeholder={placeholder}
        className={`form-popup__input tel-input__input ${error ? "error" : ""}`}
        type="text"
        onInput={handleChange}
      />
      <div className={`tel-input__select`} ref={telInputRef}>
        <label className="tel-input__select-label">
          <img className="tel-input__select-icon" src={searchIconPath} alt="" />
          <input
            type="text"
            className="tel-input__select-input"
            placeholder="Search for countries"
            onChange={(e) => setSelectSearch(e.target.value)}
            value={selectSearch}
          />
        </label>
        <ul className="tel-input__select-list">
          {filteredCodes.length ? (
            filteredCodes.map((item) => (
              <li
                key={item.code}
                className={`tel-input__select-item ${
                  countryCode === item.code ? "active" : ""
                }`}
                onClick={() => selectCountry(item.code)}
              >
                <div className="tel-input__flag">
                  {<Flag size={20} country={item.code} />}
                </div>
                <span className="tel-input__select-text">
                  {item.name} ({item.callingCode}
                  {item.suffix})
                </span>
              </li>
            ))
          ) : (
            <p>No country found</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TelInput;
