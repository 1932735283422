export const RoutePath = {
  ABOUT: "about",
  DEVELOPERS: "developers",
  PARTNERS: "partners",
  PRICING: "pricing",
  FAQ: "faq",
  DOCUMENTS: "documents",
  CONTACTS: "contacts",
  LOGIN: "login",
  LATAM: "latam",
  TERMS: "terms",
  PRIVACY: "privacy",
  PROHIBITED: "prohibited",
  EVENT: "event",
} as const;
