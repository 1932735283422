import { useCallback, useEffect, useState } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const updateSize = useCallback(() => {
    const isMobileSize = window.innerWidth < 767.98;
    setIsMobile(isMobileSize);
  }, [setIsMobile]);

  useEffect(() => {
    updateSize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);

  return {
    isMobile,
  };
};
