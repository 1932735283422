import Button from "../../../components/Button/Button";
import {
  ClassNameProps,
  SectionsProps,
  SolutionData,
  SolutionsCardData,
} from "../../../types/types";
import api from "../../../api/apiContact";
import { useEffect, useState } from "react";
import CardItem from "../../../components/Illustration/Cards/CardItem/CardItem";
import Flexible from "../../../components/Illustration/Cards/Flexible/Flexible";
const ListSolutions = ({
  openModal,
  cards,
}: { cards: SolutionsCardData[] } & SectionsProps) => {
  const className = "list-solutions";

  return (
    <>
      <ul className={className}>
        {cards
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <li key={item.id} className={`${className}__item card`}>
              <div className={`${className}__inner`}>
                <h3 className={`${className}__title subtitle`}>{item.title}</h3>
                {item.text && (
                  <p className={`${className}__text text-normal`}>
                    {item.text}
                  </p>
                )}
              </div>
              {item.button && (
                <Button className={`${className}__button`} onClick={openModal}>
                  {item.button.text}
                </Button>
              )}
              {item.image && item.image.url ? (
                <img
                  src={`${item.image.url}`}
                  className={`${className}__img`}
                  alt={item.title}
                  width={item.image.width}
                  height={item.image.height}
                />
              ) : (
                <>
                  {item.svgName === "CardItem" && <CardItem />}
                  {item.svgName === "Flexible" && <Flexible />}
                </>
              )}
            </li>
          ))}
      </ul>
    </>
  );
};
export default ListSolutions;
