import React, { MouseEventHandler } from "react";
import { EmpoweringItemData, GamingData } from "../../../types/types";
type ExtensionFilterProps = {
  className: string;
  items: EmpoweringItemData[];
  activeIndex: number;
  onClick: MouseEventHandler<HTMLLIElement>;
};

const ExtensionFilter: React.FC<ExtensionFilterProps> = ({
  className,
  items,
  activeIndex,
  onClick,
}) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li
          key={item.text}
          className={`${className}__item ${
            index === activeIndex ? "active" : ""
          }`}
          data-id={item.id}
          onClick={onClick}
        >
          <p className={`${className}__text text-micro`}>{item.text}</p>
        </li>
      ))}
    </ul>
  );
};

export default ExtensionFilter;
