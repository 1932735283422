import { useCallback, useEffect, useState } from "react";
import api from "../../api/apiContact";
import Button from "../../components/Button/Button";
import Divider from "../../components/Divider/Divider";
import NavBar from "../../components/Header/NavBar/NavBar";
import LatamPopup from "../../components/LatamPopup/LatamPopup";
import Modal from "../../components/Modal/Modal";
import { FormData, LatamPopup as LatamPopupType } from "../../types/types";
import ContactFooter from "./ContactFooter/ContactFooter";

const Footer = () => {
	const [isRequestSent, setIsRequestSent] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const currentYear = new Date().getFullYear();
	const [popupData, setPopupData] = useState<LatamPopupType>();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.getLatamPopupData();
				setPopupData(res.data);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, []);
	const openModal = useCallback(() => {
		setIsModalOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const handleSubmit = useCallback((formData: FormData) => {
		console.log(formData);
		setIsModalOpen(false);
	}, []);

	return (
		<>
			<footer className='footer'>
				<Divider />
				<div className='footer__container'>
					<NavBar
						source='footer'
						className='footer'
						isOpen={isOpen}
						setOpen={setIsOpen}
					/>
					<ContactFooter
						openModal={openModal}
						className='footer__connection contact'
					/>
					<div className='footer__copy text-micro'>
						&copy; ContactPay. All Rights Reserved. 2020-{currentYear}
					</div>
				</div>
			</footer>

			{isRequestSent ? (
				<Modal
					isOpen={isRequestSent}
					onClose={() => setIsRequestSent(false)}
					className='thank-popup popup'
					title='Thank you!'
				>
					<p className='popup__text text-large'>
						Your request has been successfully sent.
					</p>
					<p className='popup__text text-large'>
						Our expert will contact you soon.
					</p>
					<Button
						className='popup__button'
						type='button'
						onClick={() => setIsRequestSent(false)}
					>
						Close
					</Button>
				</Modal>
			) : (
				<Modal
					isOpen={isModalOpen}
					onClose={closeModal}
					className='popup'
					title='Share your request with our sales team'
				>
					<LatamPopup
						data={popupData}
						className='form-popup'
						onSubmit={handleSubmit}
						setIsOpen={setIsOpen}
						setIsRequestSent={setIsRequestSent}
						locale='es-AR'
					/>
				</Modal>
			)}
		</>
	);
};
export default Footer;
