import { MouseEvent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HeaderProps, MenuItem } from "../../../types/types";
import Login from "../Login/Login";
import api from "../../../api/apiContact";
import { useIsMobile } from "helpers/use-is-mobile";

const NavBar: React.FC<HeaderProps> = ({ className, setOpen, source }) => {
  const [activeItem, setActiveItem] = useState<HTMLLIElement | null>(null);
  const { isMobile } = useIsMobile();
  const location = useLocation();

  const onClick = (e: MouseEvent<HTMLLIElement>) => {
    window.scrollTo(0, 0);
    const target = e.target as HTMLLIElement;
    if (activeItem !== null) {
      activeItem.classList.remove("active");
    }
    target.classList.add("active");
    setActiveItem(target);
    setOpen(false);
  };

  const [menuItems, setMenuItems] = useState<MenuItem[]>();

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.getMenuData();
      setMenuItems(res.data.items);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const currentActiveItem = document.querySelector(".active");
    if (currentActiveItem !== null) {
      currentActiveItem.classList.remove("active");
    }
    const activeLink = Array.from(document.querySelectorAll(".link")).find(
      (link) => link.getAttribute("href") === location.pathname
    );
    if (activeLink !== undefined) {
      setActiveItem(activeLink.parentElement as HTMLLIElement);
      activeLink.parentElement?.classList.add("active");
    } else {
      setActiveItem(null);
    }
  }, [location]);

  return (
    <ul className={`${className}__list`}>
      {menuItems &&
        menuItems.map((item) => {
          const isActive = location.pathname === item.path;

          return (
            <li
              key={item.id}
              className={`${className}__item ${isActive ? "active" : ""}`}
              onClick={onClick}
            >
              <Link to={item.path} className={`${className}__link link`}>
                <span className={`${className}__text text-menu`}>
                  {item.label}
                </span>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default NavBar;
