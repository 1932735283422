import { ButtonProps } from '../../types/types';

const Button = ({
  variant,
  children,
  type,
  className,
  onClick,
  ...props
}: ButtonProps) => {
  let classNames = 'button';
  if (variant === 'secondary') {
    className += ' button--secondary';
  }

  return (
    <button
      className={`${className} ${classNames}`}
      {...props}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
