import ListStarted from "./ListStarted/ListStarted";
import Button from "../Button/Button";
import Divider from "../../components/Divider/Divider";
import { SectionsProps, GetStartedData } from "../../types/types";
const Started = ({
  openModal,
  data,
}: SectionsProps & { data: GetStartedData }) => {
  return (
    <section className="started">
      <Divider />
      <div className="started__container">
        <h2 className="started__title title color">
          {data.titleColored}
          <span className="title title">&nbsp; {data.title}</span>
        </h2>
        <div className="started__inner">
          <ListStarted
            className="started-list"
            data={{
              text: data.text,
              cards: data.cards,
            }}
          />
        </div>
        <Button className="started__button" onClick={openModal}>
          {data.button.text}
        </Button>
      </div>
    </section>
  );
};
export default Started;
