import { ClassNameProps, SocialData } from "../../types/types";
import React from "react";

interface SocialProps extends ClassNameProps {
  socials: SocialData[];
}

const Social: React.FC<SocialProps> = ({ className, socials }) => {
  const render = (data: SocialData) => {
    switch (data.__component) {
      case "socials.email":
        return (
          <a
            href={`mailto:${data.email}?subject=${data.subject}`}
            className="social__link"
            target="_blank"
          >
            <img
              src={`${data.image.url}`}
              className={`${className}__img`}
              alt={data.title}
            />
          </a>
        );
      case "socials.phone":
        return (
          <a
            href={`tel:${data.phone}`}
            className="social__link"
            target="_blank"
          >
            <img
              src={`${data.image.url}`}
              className={`${className}__img`}
              alt={data.title}
            />
          </a>
        );
      default:
        return (
          <a href={data.link} className="social__link" target="_blank">
            <img
              src={`${data.image.url}`}
              className={`${className}__img`}
              alt={data.title}
            />
          </a>
        );
    }
  };

  return (
    <ul className={className}>
      {socials.map((social) => (
        <li
          key={social.__component || social.id}
          className={`${className}__item`}
        >
          {render(social)}
        </li>
      ))}
      {/*{social.map(({ id,  }) => (
        <li key={id} className={`${className}__item`}>
          <a
            href={`${?.href || ''}${
              ?.name !== undefined && ?.name !== null
                ? ?.name
                : ''
            }${
              ?.subject !== undefined && ?.subject !== null
                ? ?.subject
                : ''
            }`}
            target='_blank'
            rel='noopener noreferrer'
            className={`${className}__link`}
          >
            {?.svg ? (
              <svg
                className={`${className}__svg`}
                width={?.svg.props.width}
                height={?.svg.props.height}
                viewBox={?.svg.props.viewBox}
                xmlns={?.svg.props.xmlns}
              >
                {?.svg.props.children.map(
                  (child: any, index: number) => {
                    const childProps = {
                      ...child.props,
                      key: index,
                    };
                    return React.createElement(child.type, childProps);
                  }
                )}
              </svg>
            ) : ?.img ? (
              <img
                width={`${?.img?.width}px`}
                height={`${?.img?.height}px`}
                src={`${?.img?.url}`}
                className={`${className}__img`}
                alt={?.title}
              />
            ) : (
              <span className={`${className}__text text-medium`}>
                {?.title}
              </span>
            )}
          </a>
          {?.insert && (
            <span className={`${className}__text text-micro`}>
              {?.title}
            </span>
          )}
        </li>
      ))}*/}
    </ul>
  );
};

export default Social;
