import { useEffect } from 'react';
import { ModalProps } from '../../types/types';
import Close from './Close';

const Modal = ({
  isOpen,
  showPopup,
  onClose,
  title,
  className,
  titleColor,
  children,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('popup_show');
    } else {
      document.body.classList.remove('popup_show');
    }
    if (showPopup) {
      document.body.classList.add('show');
    } else {
      document.body.classList.remove('show');
    }

    return () => {
      document.body.classList.remove('popup_show');
      document.body.classList.remove('show');
    };
  }, [isOpen, showPopup]);

  if (!isOpen && !showPopup) {
    return null;
  }
  return (
    <div
      className={`${className} ${isOpen ? 'popup_show' : ''} ${
        showPopup ? 'show' : ''
      }`}
    >
      <div className={`${className}__wrapper`}>
        <div className={`${className}__content`}>
          <button onClick={onClose} className={`${className}__close`}>
            <Close />
          </button>
          {className.includes('popup') ? (
            <div className={`popup__inner`}>
              <div className={`popup__info`}>
                {titleColor ? (
                  <h2 className={`popup__title title`}>
                    <span className='title color'>{titleColor}</span>
                    <span className='title'>{title}</span>
                  </h2>
                ) : (
                  <h2 className={`popup__title title`}>{title}</h2>
                )}
                {children}
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;
