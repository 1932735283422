import { PartnersData } from "types/types";
import Divider from "../../components/Divider/Divider";

const Partners = ({ data }: { data: PartnersData }) => {
  return (
    <>
      <section className="partners">
        <Divider />
        <div className="partners__container">
          <h2 className="partners__title title color">
            {data.titleColored}
            <span className="title"> {data.title}</span>
          </h2>
          <ul className="partners__list">
            {data.items.map(({ id, name, image }) => (
              <li key={id} className={`partners__item`}>
                <img
                  width={`${image?.width}px`}
                  height={`${image?.height}px`}
                  className="partners__img"
                  src={`${image?.url}`}
                  alt={name}
                />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};
export default Partners;
