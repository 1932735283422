import { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import { ClassNameProps, CookieData } from "../../types/types";
import api from "../../api/apiContact";
import parse from "html-react-parser";

const CookiePopup: React.FC<ClassNameProps> = ({ className }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [cookieData, setCookieData] = useState<CookieData>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getCookieData();
        setCookieData(res.data);

        const cookiecook = getCookie("cookiecook");
        if (cookiecook !== "no") {
          setShowPopup(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false);
    const date = new Date();
    date.setDate(date.getDate() + 30);

    const cookieSettings = {
      path: "/",
      expires: date.toUTCString(),
      sameSite: "None",
      secure: true,
    };

    const cookieString = Object.entries(cookieSettings)
      .map(([key, value]) => `${key}=${value}`)
      .join("; ");

    document.cookie = `cookiecook=no; ${cookieString}`;
  };
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };

  return (
    <Modal showPopup={showPopup} onClose={handlePopupClose} className="cookie">
      {cookieData && (
        <>
          <div className={`${className}__inner text-normal cookie__text`}>
            {parse(cookieData.text)}
          </div>
          <Button className={`${className}__button`} onClick={handlePopupClose}>
            {cookieData.button.text}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default CookiePopup;
