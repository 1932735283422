import { useState } from "react";
import { AdvantagesCardData } from "../../../types/types";

const TabsBenefists = ({ cards }: { cards: AdvantagesCardData[] }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const className = "pays";

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className={className}>
      <div className={`${className}__nav`}>
        {cards.map(({ id, title, text, shortTitle }, index: number) => (
          <div key={id} className={`${className}__info`}>
            <button
              key={id}
              className={`${className}__item ${
                activeTabIndex === index ? `${className}__item--active` : ""
              }`}
              onClick={() => handleTabClick(index)}
            >
              <span className={`${className}__number text-micro`}>
                0{index + 1}.
              </span>
              <span className={`${className}__text subtitle`}>
                {activeTabIndex === index ? title ?? shortTitle : shortTitle}
              </span>
            </button>
            <div
              className={`${className}__content text-small ${
                activeTabIndex === index ? `active` : ""
              }`}
            >
              {text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsBenefists;
