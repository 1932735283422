import { useEffect, useState, MouseEventHandler } from "react";
import ExtensionFilter from "./ExtensionFilter/ExtensionFilter";
import { SectionsProps, EmpoweringData } from "../../types/types";
import Divider from "../../components/Divider/Divider";
import { motion } from "framer-motion";
import Button from "../Button/Button";

const Gaming = ({
  openModal,
  data,
}: SectionsProps & { data: EmpoweringData }) => {
  const [isMobile, setIsMobile] = useState(false);
  const defaultActiveId = 0;
  const defaultActiveIndex = data.items.findIndex(
    (item) => item.id === defaultActiveId
  );
  const [activeIndex, setActiveIndex] = useState<number>(
    defaultActiveIndex !== -1 ? defaultActiveIndex : 0
  );
  const activeItem = data.items[activeIndex];
  const activeText = activeItem?.text;
  const activeImgUrl = `${activeItem?.image?.url}`;

  const onClick: MouseEventHandler<HTMLLIElement> = (e) => {
    const target = e.currentTarget;
    const clickedId = parseInt(target.dataset.id || "0", 10);
    const clickedIndex = data.items.findIndex((item) => item.id === clickedId);

    if (clickedIndex !== -1) {
      setActiveIndex(clickedIndex);
    }
  };
  const textElements = [];

  if (data.text) {
    const textElement = (
      <p className="gaming__text text-large" key="1">
        {data.text}
        {data.textHighlighted && (
          <span className="gaming__text--color text-large" key="2">
            &nbsp;{data.textHighlighted}
          </span>
        )}
      </p>
    );
    textElements.push(textElement);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767.98);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.items.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [data.items.length]);

  return (
    <section className="gaming">
      <Divider />
      <div className="gaming__container">
        <div className="gaming__wrap">
          <div className="gaming__info">
            <h2 className="gaming__title title color">
              <span>{data.firstTitle}&nbsp;</span>
              <motion.span
                className="title"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                key={activeItem?.id}
              >
                {activeText}
              </motion.span>
              <span>{data.secondTitle}</span>
            </h2>
            {!isMobile && (
              <ExtensionFilter
                className="extension"
                items={data.items}
                activeIndex={activeIndex}
                onClick={onClick}
              />
            )}
            {textElements}
          </div>
          <div className="gaming__img">
            {data.items.length > 0 && (
              <img
                src={activeImgUrl}
                width="500px"
                height="auto"
                alt="Empowering Growth"
              />
            )}
          </div>
        </div>
        {isMobile && (
          <ExtensionFilter
            className="extension"
            items={data.items}
            activeIndex={activeIndex}
            onClick={onClick}
          />
        )}
        <Button onClick={openModal} className="gaming__button">
          {data.button.text}
        </Button>
      </div>
    </section>
  );
};

export default Gaming;
