import { FeaturesCardData } from "../../../types/types";
import { useIsMobile } from "helpers/use-is-mobile";

const ListFeatures = ({ cards }: { cards: FeaturesCardData[] }) => {
  const { isMobile } = useIsMobile();
  const className = "features-list";

  return (
    <>
      <ul className={className}>
        {cards.map(({ id, title, image, items }) => (
          <li key={id} className={`${className}__item card`}>
            <div className={`${className}__inner`}>
              <h3 className={`${className}__title subtitle`}>{title}</h3>
              {items && items.length > 0 && (
                <div className={`${className}__box`}>
                  {items.map((item) => (
                    <p
                      key={item.id}
                      className={`${className}__text text-normal`}
                    >
                      {item.title}
                    </p>
                  ))}
                </div>
              )}
            </div>
            {!isMobile && image && (
              <img
                width={`${image?.width}px`}
                height={`${image?.height}px`}
                src={`${image?.url}`}
                className={`${className}__img`}
                alt={title}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
export default ListFeatures;
