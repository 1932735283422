import { GetStartedCardData } from "../../../types/types";

interface ListStartedProps {
  className: string;
  data: {
    text: string;
    cards: GetStartedCardData[];
  };
}

const ListStarted = ({ className, data }: ListStartedProps) => {
  return (
    <>
      <ul className={className}>
        {data.cards.map(({ id, title, text, image }) => (
          <li key={id} className={`${className}__item`}>
            {image && (
              <img
                width={`${image?.width}px`}
                height={`${image?.height}px`}
                src={`${image?.url}`}
                className={`${className}__img`}
                alt={title}
              />
            )}
            <div className={`${className}__inner`}>
              <h3 className={`${className}__title subtitle`}>{title}</h3>
              <p className={`${className}__text text-normal`}>{text}</p>
            </div>
          </li>
        ))}
      </ul>
      <p className={`${className}__text text-large`}>{data.text}</p>
    </>
  );
};
export default ListStarted;
