import { motion } from "framer-motion";
import ListOptions from "./ListOptions/ListOptions";
import Ellipse from "../../components/Ellipse/Ellipse";
import { OptionsData, SectionsDataProps } from "../../types/types";

const Options = ({ data }: { data: OptionsData }) => {
  return (
    <motion.section
      className="options"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Ellipse />
      <div className="options__container grid-fons">
        <motion.div
          className="options__info"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <h2 className="options__title title color">
            {data.title}
            <span className="title">&nbsp;{data.titleColored}</span>
          </h2>
          <motion.p
            className="options__text subtitle"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            {data.text}
          </motion.p>
        </motion.div>
        <motion.div
          className="options__inner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <ListOptions cards={data.cards} />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Options;
