const CardItem = () => {
  return (
    <div className='animation__wrap'>
      <div className='maincard'></div>
      <div className='lines__wrap'>
        <div className='lines__top'>
          <svg
            width='78'
            height='47'
            viewBox='0 0 78 47'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='LineTop'
              d='M0.251953 46.4956H21.0611C30.4499 46.4956 38.0611 38.8844 38.0611 29.4956V18.4956C38.0611 9.10676 45.6723 1.49561 55.0611 1.49561H77.252'
              stroke='url(#paint0_linear_328_3060)'
            />
            <defs>
              <linearGradient
                id='paint0_linear_328_3060'
                x1='0.251953'
                y1='1.49561'
                x2='77.252'
                y2='1.49561'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0.307309' stopColor='#8000FF' />
                <stop offset='1' stopColor='#FFA800' />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className='lines__middle'>
          <svg
            width='78'
            height='2'
            viewBox='0 0 78 2'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.251953 0.620605H38.752H77.252'
              stroke='url(#paint0_linear_328_3059)'
            />
            <defs>
              <linearGradient
                id='paint0_linear_328_3059'
                x1='0.251953'
                y1='0.620605'
                x2='77.252'
                y2='0.620605'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0.307309' stopColor='#8000FF' />
                <stop offset='1' stopColor='#FF0000' />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className='lines__bottom'>
          <svg
            width='78'
            height='47'
            viewBox='0 0 78 47'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.251953 0.745605H21.0611C30.4499 0.745605 38.0611 8.35676 38.0611 17.7456V28.7456C38.0611 38.1344 45.6723 45.7456 55.0611 45.7456H77.252'
              stroke='url(#paint0_linear_328_3061)'
            />
            <defs>
              <linearGradient
                id='paint0_linear_328_3061'
                x1='0.251953'
                y1='45.7456'
                x2='77.252'
                y2='45.7456'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0.307309' stopColor='#8000FF' />
                <stop offset='1' stopColor='#14FF00' />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default CardItem;
