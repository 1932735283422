import React, { useEffect, useRef } from 'react';
import Globe, { GlobeMethods } from 'react-globe.gl';
import globeColor from '../../../assets/img/General/globecolor.svg';

import countries from '../../../data/countries.json';
// 
import arcData from '../../../data/arcData.json';
import { ClassNameProps } from '../../../types/types';

const Globes: React.FC<ClassNameProps> = ({ className }) => {
  const globeRef = useRef<GlobeMethods>();

  useEffect(() => {
    const controls = globeRef.current?.controls();

    if (controls) {
      controls.autoRotate = true;
      controls.enableZoom = false;
      controls.autoRotateSpeed = 0.35; // Уменьшаем скорость автоматического вращения
      controls.rotateSpeed = 0.35; // Уменьшаем скорость ручного вращения
    }
  }, []);

  // if (window.innerWidth < 768) {
  //   return null;
  // }

  const getArcColor = () => {
    const gradient = ['rgba(230, 130, 226, 0.85)', 'rgba(137, 143, 204, 0.5)', 'rgba(204, 159, 137, 0.5)']; // RGB с 85% прозрачностью
    const randomIndex = Math.floor(Math.random() * gradient.length);
    return gradient[randomIndex];
  };

  const arcDashSpeed = (arc: any) => {
    return 0. + Math.random() * 0.7;
  };
  if (window.innerWidth < 768) {
    return null;
  }

  return (
    <div className={className}>
      <Globe
        ref={globeRef}
        width={674}
        height={764}
        backgroundColor="rgba(0, 0, 0, 0)"
        globeImageUrl={globeColor}
        arcsData={arcData}
        arcStroke={0.2}
        arcDashLength={0.6}
        arcDashGap={0.5}
        arcDashAnimateTime={10000} // Увеличиваем время анимации дуг
        arcColor={() => getArcColor()}
        pointColor={() => '#FFFF00'}
        hexPolygonsData={countries.features}
        hexPolygonMargin={0.6}
        hexPolygonColor={() => 'rgba(236,231,255, 1)'}
        showAtmosphere={true}
        atmosphereColor="#a48dfb"
        atmosphereAltitude={0.1}
        arcDashInitialGap={() => Math.random()} // Задаем случайное начальное смещение анимации дуг
        // @ts-ignore
        arcDashSpeed={arcDashSpeed}
      />
    </div>
  );
};

export default Globes;
