import { ContentPageData, EventPageData } from "types/types";
import api from "../api/apiContact";
import { useEffect, useState } from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import parse from "html-react-parser";

const Event = () => {
  const [pageData, setPageData] = useState<EventPageData>();
  const [isOpen, setIsOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getEventPageData();

        setPageData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const subscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!pageData?.event || !email.trim().length) return;
    await api
      .subscribeToEvent(pageData.event.id.toString(), email)
      .then((res) => {
        if (res) {
          setIsFormSubmitted(true);
          setTimeout(() => {
            setIsOpen(false);
          }, 1000);
        }
      });
  };
  return (
    <div className="event">
      {pageData && (
        <div>
          <div>
            <div className="event__container event__intro">
              <div className="event__blocks-item">
                <h1 className="title color">{pageData.intro.titleColored}
                <span className="title"> {pageData.intro.title}</span></h1>
                <p className="text">{pageData.intro.text}</p>
                <Button type="button" onClick={() => setIsOpen(true)}>
                  {pageData.intro.button.text}
                </Button>
              </div>
              <img
                className="event__blocks-item event__intro-image"
                src={pageData.intro.image.url}
                alt=""
              />
            </div>
          </div>

          <div>
            <div className="divider"></div>
            <section className="event__container event__join">
              <img
                className="event__blocks-item event__join-image"
                src={pageData.join.image.url}
                alt=""
              />
              <div className="event__blocks-item">
                <h1 className="title">{pageData.join.title}</h1>
                <h1 className="title color">{pageData.join.titleColored}</h1>
                <h3>{pageData.join.subheader}</h3>
                <p className="text">{parse(pageData.join.content)}</p>
              </div>
            </section>
          </div>

          <div>
            <div className="divider"></div>

            <section className="event__container event__program">
              <h1 className="title event__program-title">
                {pageData.program.title}
              </h1>
              <p className="text event__program-text">
                {pageData.program.text}
              </p>
              <div className="event__program-members">
                {pageData.program.members.map((member) => (
                  <div className="event__member">
                    <img
                      className="event__member-image"
                      src={member.image.url}
                      alt=""
                    />
                    <p className="text event__member-text">{member.text}</p>
                    <p className="text event__member-name">{member.name}</p>
                    <p className="text event__member-job">{member.job}</p>
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="event__designed">
            <div className="divider"></div>
            <section className="event__container">
              <h1 className="title event__designed-title">
                {pageData.designed.firstTitle}
              </h1>
              <div className="event__designed-items">
                {pageData.designed.items.map((item) => (
                  <div className="event__item">
                    <img
                      className="event__item-image"
                      src={item.image.url}
                      alt=""
                    />
                    <h2 className="event__item-title">{item.title}</h2>
                    <p className="text event__item-text">{item.text}</p>
                  </div>
                ))}
              </div>
              <h1 className="title event__designed-title title-second">
                {pageData.designed.secondTitle}{" "}
                <span className="color">
                  {pageData.designed.secondTitleColored}
                </span>
              </h1>
              <p className="text event__designed-text">
                {pageData.designed.text}
              </p>
              <Button type="button" onClick={() => setIsOpen(true)}>
                {pageData.designed.button.text}
              </Button>
            </section>
          </div>

          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="event-modal popup"
            title={"Subscribe for event"}
          >
            {!isFormSubmitted ? (
              <form
                className="event-modal-inner"
                onSubmit={(e) => subscribe(e)}
              >
                <p className="text-large">Get latest news about our Event</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-popup__input"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <br />
                <br />
                <button className="form__button button event-button">
                  Subscribe
                </button>
              </form>
            ) : (
              <p className="subtitle">
                Congrats!!! We successfully submit your email.
              </p>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Event;
