import { ConnectCardData } from "../../types/types";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

interface ListConectProps {
  cards: ConnectCardData[];
}

const ListConect = ({ cards }: ListConectProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  //const [conectList, setConectList] = useState<ConectData[]>([]);

  const handleResizeAndScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const elemBottom = rect.bottom;
      const windowHeight = window.innerHeight;
      const isVisible = elemBottom - 150 <= windowHeight;
      setIsVisible(isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeAndScroll);
    window.addEventListener("scroll", handleResizeAndScroll);

    return () => {
      window.removeEventListener("resize", handleResizeAndScroll);
      window.removeEventListener("scroll", handleResizeAndScroll);
    };
  }, []);
  return (
    <section className="list">
      <div className={`list__container`}>
        <ul className={`list-connect`} ref={ref}>
          {cards.map(({ id, text, image }, index: number) => (
            <motion.li
              key={id}
              className="list-connect__item card"
              initial={{ x: -2000 }}
              animate={{ x: isVisible ? 0 : -2000 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <img
                width={`${image.width}px`}
                height={`${image.height}px`}
                src={`${image.url}`}
                className={`list-connect__img`}
                alt={text}
              />
              <span className="list-connect__text text-small">{text}</span>
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default ListConect;
