const Flexible = () => {
  return (
    <div className='card-animation'>
      <div className='circle circle__color circle__color-euro'></div>
      <div className='circle circle__muted circle__muted-etherium'></div>
      <div className='circle circle__color circle__color-usd'></div>
      <div className='circle circle__muted circle__muted-yen'></div>
      <div className='circle circle__color circle__color-bitcoin'></div>
    </div>
  );
};
export default Flexible;
