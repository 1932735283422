import ListSolutions from "../../components/Solutions/ListSolutions/ListSolutions";
import Divider from "../../components/Divider/Divider";
import { SectionsProps, SolutionsData } from "../../types/types";
const Solutions = ({
  openModal,
  data,
}: SectionsProps & { data: SolutionsData }) => {
  return (
    <>
      <section className="solutions">
        <Divider />
        <div className="solutions__container">
          <div className="solutions__info">
            <h2 className="solutions__title title color">
              {data.title}
              <span className="title">&nbsp;{data.titleColored}</span>
            </h2>
            <p className="solutions__text subtitle">{data.text}</p>
          </div>
          <ListSolutions cards={data.cards} openModal={openModal} />
        </div>
      </section>
    </>
  );
};
export default Solutions;
