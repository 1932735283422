import { OptionsCardData } from "../../../types/types";

const ListOptions = ({ cards }: { cards: OptionsCardData[] }) => {
  return (
    <>
      <ul className="list-options">
        {cards.map(({ id, title, text, image }) => (
          <li key={id} className={`list-options__item`}>
            <div className={`list-options__wrap`}>
              <img
                width={`${image?.width}px`}
                height={`${image?.height}px`}
                src={`${image?.url}`}
                className={`list-options__img`}
                alt={text}
              />
              <span className={`text-medium`}>{title} —</span>
              <span className={`list-options__color text-medium`}>
                &nbsp;{text}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ListOptions;
