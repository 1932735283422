import TabsBenefists from "./TabsBenefits/TabsBenefists";
import img from "../../assets/img/Illustration/Stars.svg";
import Divider from "../../components/Divider/Divider";
import Button from "../Button/Button";
import { SectionsProps, AdvantagesData } from "../../types/types";

const Benefits = ({
  openModal,
  data,
}: SectionsProps & { data: AdvantagesData }) => {
  return (
    <section className="benefits">
      <Divider />
      <div className="benefits__container">
        <div className="benefits__info">
          <h2 className="benefits__title title ">
            {data.title}
            <span className="title color"> {data.titleColored}</span>
          </h2>
          <p className="benefits__text subtitle">{data.text}</p>
        </div>
        <div className="benefits__img">
          <img src={img} alt="Benefits" width="100%" height="100%" />
        </div>
        <TabsBenefists cards={data.cards} />
        <Button className="benefits__button" onClick={openModal}>
          {data.button.text}
        </Button>
      </div>
    </section>
  );
};
export default Benefits;
