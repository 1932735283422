import Divider from "../../components/Divider/Divider";
import ListFeatures from "./ListFeatures/ListFeatures";
import { FeaturesData } from "../../types/types";

const Features = ({ data }: { data: FeaturesData }) => {
  return (
    <section className="features">
      <Divider />
      <div className="features__container grid-fons">
        <div className="features__info">
          <h2 className="features__title title ">
            {data.title}
            <span className="title color">&nbsp; {data.titleColored}</span>
          </h2>
          <p className="features__text subtitle">{data.text}</p>
        </div>
        <ListFeatures cards={data.cards} />
      </div>
    </section>
  );
};
export default Features;
