//@ts-nocheck
import axios from "axios";
import Cookies from "js-cookie";
//import { setupCache } from "axios-cache-interceptor";

type FormData = {
  connection: "" | "Phone" | "E-mail" | "Whatsapp" | "Telegram";
  connectionItem: string;
  nameUser: string;
  message: string;
  privacyPolicy: boolean;
  locale?: string;
};
export const API_URL = `https://contact-back.up.railway.app/`;
//export const API_URL = `http://localhost:1337/`;

const instance =
  //setupCache(
  axios.create({
    baseURL: API_URL,
  });
//);

class Api {
  getInstance() {
    return instance;
  }

  async getMenuData() {
    try {
      const response = await instance.get("/api/menu?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getEventData() {
    try {
      const response = await instance.get("/api/events/last");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async subscribeToEvent(id: string, email: string) {
    try {
      const response = await instance.post(`/api/events/${id}/subscribe`, {
        email,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getFooterData() {
    try {
      const response = await instance.get("/api/footer?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getCookieData() {
    try {
      const response = await instance.get("/api/cookie-popup?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getPopupData(locale = "en") {
    try {
      const response = await instance.get(
        `/api/share-popup?populate=deep&locale=${locale}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getLatamPopupData() {
    try {
      const response = await instance.get(
        `/api/latam-share-popup?populate=deep`
      );
      console.log(response);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getEventPageData(locale = "en") {
    try {
      const response = await instance.get(
        `/api/event-page?populate=deep&locale=${locale}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getMainPageData(locale = "en") {
    try {
      const response = await instance.get(
        `/api/main-page?populate=deep&locale=${locale}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getContactsPageData() {
    try {
      const response = await instance.get("/api/contacts-page?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getAboutPageData() {
    try {
      const response = await instance.get("/api/about-page?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  async getTermsPageData() {
    try {
      const response = await instance.get(
        "/api/terms-and-conditions-page?populate=deep"
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  async getPrivacyPageData() {
    try {
      const response = await instance.get(
        "/api/privacy-policy-page?populate=deep"
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  async getProhibitedPageData() {
    try {
      const response = await instance.get("/api/prohibited-page?populate=deep");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async sendEmail(token: string, email: string, source: string) {
    try {
      const res = await instance.post(`/api/check-email?token=${token}`, {
        email,
        source,
        emailTemplate: "subscribe",
      });

      return res.status === 200;
    } catch (error) {
      console.log(error);
    }
  }
  async sendPopup(token: string, formData: FormData) {
    (window as any).fbq("track", "Lead");
    const utm = Cookies.get("utm");

    try {
      const res = await instance.post(`/api/check-email?token=${token}`, {
        ...formData,
        utm,
      });

      return res.status === 200;
    } catch (error) {
      console.log(error);
    }
  }
}

const api = new Api();
export default api;
