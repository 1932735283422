import FormEmail from "../../FormEmail/FormEmail";
import Social from "../../Social/Social";
import {
  ClassNameProps,
  EmailReques,
  SocialData,
  FooterData,
  ContactFooterProps,
} from "../../../types/types";
import api from "../../../api/apiContact";
import { useCallback, useEffect, useState } from "react";
import { useIsMobile } from "helpers/use-is-mobile";
import Button from "components/Button/Button";
import parse from "html-react-parser";

const ContactFooter = ({
  className,
  openModal,
}: ClassNameProps & ContactFooterProps) => {
  const [footerData, setFooterData] = useState<FooterData>();
  const { isMobile } = useIsMobile();

  const fetchData = useCallback(async () => {
    try {
      const res = await api.getFooterData();
      setFooterData(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {footerData && (
        <div className={className}>
          <div className="contact__info">
            <div className="contact__inner text">
              {parse(footerData.firstContent)}
            </div>
            <Social className="social" socials={footerData.socials} />
            <div className="contact__description text-menu">
              {parse(footerData.secondContent)}
            </div>
          </div>
          {
            <div className={`contact__card ${isMobile ? "" : "card"}`}>
              <p className="contact__text text">{footerData.card.text}</p>
              <Button onClick={openModal}>{footerData.card.button.text}</Button>
            </div>
          }
        </div>
      )}
    </>
  );
};
export default ContactFooter;
