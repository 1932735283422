import { Link } from 'react-router-dom';
import notFoundImage from '../../assets/img/General/404.svg';

const NotFound = () => {
  return (
    <article className='not-found'>
      <img
        src={notFoundImage}
        alt='404 Not found'
        width='600px'
        height='100%'
      />
      <div className='not-found__container'>
        <h2 className='title '>
          Oops! <span className='title color'>Page does not exist</span>
        </h2>
        <Link to='/' className='button'>
          Back to home page
        </Link>
      </div>
    </article>
  );
};

export default NotFound;
