import React, { useState, useEffect } from "react";
import Logo from "../../components/Logo/Logo";
import NavBar from "./NavBar/NavBar";
import Button from "../../components/Button/Button";
import { useIsMobile } from "../../helpers/use-is-mobile";
import Login from "./Login/Login";

const Header = () => {
  const [isOpen, setOpen] = useState(true);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (isOpen) {
      setOpen(false);
    }
  }, [isMobile]);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const getMenuButtonText = () => {
    return isOpen ? "CLOSE" : "MENU";
  };

  return (
    <header className="header">
      <div className="header__container">
        <Logo />
        <div className={`header__menu menu ${!isOpen ? "closed" : ""}`}>
          <nav className="menu__body">
            <NavBar
              className="menu"
              source="header"
              setOpen={setOpen}
              isMobile={isMobile}
            />
          </nav>
        </div>
        {


  isMobile && (
    <Button
      className="header__button text-menu"
      variant="secondary"
      onClick={toggleMenu}
    >
      {getMenuButtonText()}
    </Button>
  )

// isMobile ? (
        //   <Button
        //     className="header__button text-menu"
        //     variant="secondary"
        //     onClick={toggleMenu}
        //   >
        //     {getMenuButtonText()}
        //   </Button>
        // )
        //  : (
        //   <Login className="header__login login" />
        // )
        }
      </div>
    </header>
  );
};

export default Header;
