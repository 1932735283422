const Close = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='close__icon'>
        <circle
          id='Oval'
          cx='15'
          cy='15'
          r='15'
          transform='rotate(-90 15 15)'
          fill='url(#paint0_linear_1160_5433)'
        />
        <g id='Group'>
          <rect
            id='Rectangle'
            x='6.99902'
            y='9.12134'
            width='3'
            height='20'
            rx='1.5'
            transform='rotate(-45 6.99902 9.12134)'
            fill='#020B27'
          />
          <rect
            id='Rectangle_2'
            x='9.12012'
            y='23.2634'
            width='3'
            height='20'
            rx='1.5'
            transform='rotate(-135 9.12012 23.2634)'
            fill='#020B27'
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1160_5433'
          x1='4.54016e-08'
          y1='15.0002'
          x2='30'
          y2='15.0002'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8D97EF' />
          <stop offset='1' stopColor='#A57AF3' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Close;
